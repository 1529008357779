import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 // Assuming you have custom CSS for this component

function Abouthome() {
  const contents = [
    {
      image: "assets/img/about/f.jpg",
      title: "Empowering Communities through Technology",
      text1: "Tech to All is dedicated to providing computer training for children and single mothers, empowering them with essential skills for the future.",
      text2: "Our programs focus on closing the digital divide, ensuring that every individual has access to the tools and knowledge needed to succeed in the modern world.",
      link: "about",
    },
    {
      image: "assets/img/about/9.jpeg",
      title: "Specialized Consultation for Single Mothers and Having good Coffee time",
      text1: "Our NGO, Tinsaesms, have Coffee time  offers specialized consultation services for single mothers and their children. Our experts provide guidance and support to help single parents navigate challenges and build stronger, healthier relationships with their children.",
      text2: "Whether you're dealing with parenting issues, financial stress, or other concerns, we're here to help. Join us for a coffee time where we discuss and work through your challenges in a supportive environment. and also we gatherd to have good communication time to build family time",
      link: "single-mothers-support",
    },
    
    {
      image: "assets/img/about/R.png",
      title: "Expert Tutoring Services for Children",
      text1: "Our NGO, Tinsaesms, provides expert tutoring services for children to help them excel academically and develop their full potential. Our experienced tutors offer personalized support in various subjects, tailored to each child's needs and learning style.",
      text2: "Whether your child needs help with school subjects, exam preparation, or building confidence in their abilities, we're here to assist. Join us for engaging and effective tutoring sessions that foster a love for learning.",
      link: "childrens-tutoring",
    },
    
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,           
    autoplaySpeed: 3000,       
    arrows: false,             
  };

  return (
    <div>

      <Slider {...settings}>
        {contents.map((content, index) => (
          
          <div key={index} className="about-area about-max pt-100 pb-70">
            <div className="container-fluid">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-5 col-xxl-6">
                  <div className="about-img about-ml">
                    <img src={content.image} alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7 col-xxl-6">
                  <div className="about-content about-width">
                    <span>About Us</span>
                    <h2>{content.title}</h2>
                    <p>{content.text1}</p>
                    <p>{content.text2}</p>
                    <div className="about-btn">
                      <a href={content.link} className="default-btn active">Know More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Abouthome;
