import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Members() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,  // Enable automatic sliding
    autoplaySpeed: 3000,  // Slide every 3 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const members = [
    {
      name: "Selamawit Shiefraw",
      title: "Executive Director",
      imgSrc: "assets/img/member/1.jpg",
      facebook: "https://www.facebook.com/",
      twitter: "https://twitter.com/i/flow/login",
      instagram: "https://www.instagram.com/accounts/login/"
    },
    {
      name: "Abiot Agema",
      title: "Secretary",
      imgSrc: "assets/img/member/2.jpg",
      facebook: "https://www.facebook.com/",
      twitter: "https://twitter.com/i/flow/login",
      instagram: "https://www.instagram.com/accounts/login/"
    },
    {
      name: "Etsegenet Firdaweke",
      title: "Administrative Support Manager",
      imgSrc: "assets/img/member/3.jpg",
      facebook: "https://www.facebook.com/",
      twitter: "https://twitter.com/i/flow/login",
      instagram: "https://www.instagram.com/accounts/login/"
    },
    {
      name: "Helen Alemseged ",
      title: "Project Manager and Trainer",
      imgSrc: "assets/img/member/4.jpg",
      facebook: "https://www.facebook.com/",
      twitter: "https://twitter.com/i/flow/login",
      instagram: "https://www.instagram.com/accounts/login/"
    }
  ];

  return (
    <div className="member-area pb-70">
      <div className="container">
        <div className="section-title text-center">
          <span>Our Expert Members</span>
          <h2>Behind the Great Success of Tinsae They Playing Role</h2>
        </div>
        <Slider {...settings} className="row pt-45">
          {members.map((member, index) => (
            <div key={index} className="col-lg-3 col-sm-6">
              <div className="member-card">
                <div className="member-img">
                  <a href="">
                    <img src={member.imgSrc} alt="Member Images" />
                  </a>
                  <div className="member-content">
                    <a href="">
                      <h3>{member.name}</h3>
                    </a>
                    <span>{member.title}</span>
                    <div className="social-icon">
                      <ul>
                        <li>
                          <a href={member.facebook} target="_blank" rel="noopener noreferrer">
                            <i className="bx bxl-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href={member.twitter} target="_blank" rel="noopener noreferrer">
                            <i className="bx bxl-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href={member.instagram} target="_blank" rel="noopener noreferrer">
                            <i className="bx bxl-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Members;
